/* 3D tower loader made by: csozi | Website: www.csozi.hu*/

.loader {
    scale: 3;
    height: 100px;
    width: 80px;
  }
  
  .box {
    position: relative;
    opacity: 0;
    left: 20px;
  }
  
  .side-left {
    position: absolute;
    background-color: #286cb5;
    width: 38px;
    height: 10px;
    transform: skew(0deg, -25deg);
    top: 28px;
    left: 20px;
  }
  
  .side-right {
    position: absolute;
    background-color: #2f85e0;
    width: 38px;
    height: 10px;
    transform: skew(0deg, 25deg);
    top: 28px;
    left: -18px;
  }
  
  .side-top {
    position: absolute;
    background-color: #5fa8f5;
    width: 40px;
    height: 40px;
    rotate: 45deg;
    transform: skew(-20deg, -20deg);
  }
  
  .box-1 {
    animation: from-left 8s infinite;
  }
  
  .box-2 {
    animation: from-right 8s infinite;
    animation-delay: 2s;
  }
  
  .box-3 {
    animation: from-left 8s infinite;
    animation-delay: 4s;
  }
  
  .box-4 {
    animation: from-right 8s infinite;
    animation-delay: 6s;
  }
  
  @keyframes from-left {
    0% {
      z-index: 20;
      opacity: 0;
      translate: -40px -12px;
    }
  
    20% {
      z-index: 10;
      opacity: 1;
      translate: 0px 0px;
    }
  
    40% {
      z-index: 9;
      translate: 0px 8px;
    }
  
    60% {
      z-index: 8;
      translate: 0px 16px;
    }
  
    80% {
      z-index: 7;
      opacity: 1;
      translate: 0px 24px;
    }
  
    100% {
      z-index: 5;
      translate: 0px 60px;
      opacity: 0;
    }
  }
  
  @keyframes from-right {
    0% {
      z-index: 20;
      opacity: 0;
      translate: 40px -12px;
    }
  
    20% {
      z-index: 10;
      opacity: 1;
      translate: 0px 0px;
    }
  
    40% {
      z-index: 9;
      translate: 0px 8px;
    }
  
    60% {
      z-index: 8;
      translate: 0px 16px;
    }
  
    80% {
      z-index: 7;
      opacity: 1;
      translate: 0px 24px;
    }
  
    100% {
      z-index: 5;
      translate: 0px 60px;
      opacity: 0;
    }
  }