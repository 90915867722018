@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@300&display=swap");


@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
    font-family: "Mustica Pro Hairline";
    src: url("res/fonts/MusticaPro-SemiBold.otf") format("opentype");
}
@font-face {
    font-family: "Quicksand";
    src: url("res/fonts/Quicksand-VariableFont_wght.ttf") format("opentype");
}
