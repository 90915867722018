
/* === LODAING SCREEN === */
/* span {
    position: relative;
    top: 0;  
    
    text-transform: uppercase;  
    opacity: 0;
    transform: rotateX(-90deg);
  }
  
  .let1 {
    animation: drop 0.5s ease-in-out infinite;
    animation-delay: 1s;
  }
  
  .let2 {
    animation: drop 1.5s ease-in-out infinite;
    animation-delay: 2.s;
  }
  
  .let3 {
    animation: drop 1s ease-in-out infinite;
    animation-delay: 1.75s;
  }
  
  .let4 {
    animation: drop 2s ease-in-out infinite;
    animation-delay: 3.75s;
  
  }
  
  .let5 {
    animation: drop 1.5s ease-in-out infinite;
    animation-delay: 1.5s;
  }
  
  .let6 {
    animation: drop 1s ease-in-out infinite;
    animation-delay: 0.25s;
  }

  .let7 {
    animation: drop 1.2s ease-in-out infinite;
    animation-delay: 1.1s;
  }

  .let8 {
    animation: drop 1.4s ease-in-out infinite;
    animation-delay: 0.85s;
  }

  .let9 {
    animation: drop 1.25s ease-in-out infinite;
    animation-delay: 1s;
  }

  .let10 {
    animation: drop 1.5s ease-in-out infinite;
    animation-delay: 2.25s;
  }

  .let11 {
    animation: drop 1s ease-in-out infinite;
    animation-delay: 3.25s;
  }

  .let12 {
    animation: drop 2s ease-in-out infinite;
    animation-delay: 1.25s;
  }

  .let13 {
    animation: drop 1s ease-in-out infinite;
    animation-delay: 0.25s;
  }
  
  
  @keyframes drop {
      10% {
          opacity: 0.5;
      }
      20% {
          opacity: 1;
          top: 3.78em;
          transform: rotateX(-360deg);
      }
      80% {
          opacity: 1;
          top: 3.78em;
          transform: rotateX(-360deg);
      }
      90% {
          opacity: 0.5;
      }
      100% {
          opacity: 0;
          top: 6.94em
      }
  } */

  .responsive-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 10;
    width: 160px;
    height: 100px;
    margin-left: -80px;
    margin-top: -50px;
    border-radius: 10px;
    background: #1e3f57;
    animation: dot1_ 6s cubic-bezier(0.55,0.3, 0.24, 0.99) infinite;
  }
  
  .responsive-loader:nth-child(2) {
    z-index: 11;
    width: 150px;
    height: 90px;
    margin-top: -45px;
    margin-left: -75px;
    border-radius: 6px;
    background: #3c517d;
    animation-name: dot2_;
  }
  
  .responsive-loader:nth-child(3) {
    z-index: 12;
    width: 40px;
    height: 20px;
    margin-top: 50px;
    margin-left: -20px;
    border-radius: 0 0 10px 10px;
    background: #6bb2cd;
    animation-name: dot3_;
  }
  
  @keyframes dot1_ {
    3%,97% {
      width: 160px;
      height: 100px;
      margin-top: -50px;
      margin-left: -80px;
    }
  
    30%,36% {
      width: 80px;
      height: 120px;
      margin-top: -60px;
      margin-left: -40px;
    }
  
    63%,69% {
      width: 40px;
      height: 80px;
      margin-top: -40px;
      margin-left: -20px;
    }
  }
  
  @keyframes dot2_ {
    3%,97% {
      height: 90px;
      width: 150px;
      margin-left: -75px;
      margin-top: -45px;
    }
  
    30%,36% {
      width: 70px;
      height: 81px;
      margin-left: -35px;
      margin-top: -48px;
    }
  
    63%,69% {
      width: 32px;
      height: 60px;
      margin-left: -16px;
      margin-top: -30px;
    }
  }
  
  @keyframes dot3_ {
    3%,97% {
      height: 20px;
      width: 40px;
      margin-left: -20px;
      margin-top: 50px;
    }
  
    30%,36% {
      width: 8px;
      height: 8px;
      margin-left: -5px;
      margin-top: 49px;
      border-radius: 16px;
    }
  
    63%,69% {
      width: 16px;
      height: 4px;
      margin-left: -8px;
      margin-top: -37px;
      border-radius: 20px;
    }
  }

/* === LOADING SCREEN 2 === */
@keyframes square-animation {
  0% {
   left: 0;
   top: 0;
  }
 
  10.5% {
   left: 0;
   top: 0;
  }
 
  12.5% {
   left: 32px;
   top: 0;
  }
 
  23% {
   left: 32px;
   top: 0;
  }
 
  25% {
   left: 64px;
   top: 0;
  }
 
  35.5% {
   left: 64px;
   top: 0;
  }
 
  37.5% {
   left: 64px;
   top: 32px;
  }
 
  48% {
   left: 64px;
   top: 32px;
  }
 
  50% {
   left: 32px;
   top: 32px;
  }
 
  60.5% {
   left: 32px;
   top: 32px;
  }
 
  62.5% {
   left: 32px;
   top: 64px;
  }
 
  73% {
   left: 32px;
   top: 64px;
  }
 
  75% {
   left: 0;
   top: 64px;
  }
 
  85.5% {
   left: 0;
   top: 64px;
  }
 
  87.5% {
   left: 0;
   top: 32px;
  }
 
  98% {
   left: 0;
   top: 32px;
  }
 
  100% {
   left: 0;
   top: 0;
  }
 }
 
 .loader {
  position: relative;
  width: 96px;
  height: 96px;
  transform: rotate(45deg);
 }
 
 .loader-square {
  position: absolute;
  top: 0;
  left: 0;
  width: 28px;
  height: 28px;
  margin: 2px;
  border-radius: 0px;
  background: white;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  animation: square-animation 10s ease-in-out infinite both;
 }
 
 .loader-square:nth-of-type(0) {
  animation-delay: 0s;
 }
 
 .loader-square:nth-of-type(1) {
  animation-delay: -1.4285714286s;
 }
 
 .loader-square:nth-of-type(2) {
  animation-delay: -2.8571428571s;
 }
 
 .loader-square:nth-of-type(3) {
  animation-delay: -4.2857142857s;
 }
 
 .loader-square:nth-of-type(4) {
  animation-delay: -5.7142857143s;
 }
 
 .loader-square:nth-of-type(5) {
  animation-delay: -7.1428571429s;
 }
 
 .loader-square:nth-of-type(6) {
  animation-delay: -8.5714285714s;
 }
 
 .loader-square:nth-of-type(7) {
  animation-delay: -10s;
 }

/* === VIDEO EFFECT BACKGROUND === */
.video-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -1;
    filter: brightness(70%);
  }

/* === CSS BACKGROUND === */
.css-bg {
  background: radial-gradient(circle, #17ACF7 10%, transparent 11%);
        background-size: 1em 1em;
        
        opacity: 0.85
}

/* === DRAWER MENU === */
.custom_menu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1;
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.3s ease-in-out;
}