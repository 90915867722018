@media (max-width: 768px) {
  ::-webkit-scrollbar {
    display: none;
  }
}

::-webkit-scrollbar {
    width: 12px;
    z-index: 1;
  }
  
  ::-webkit-scrollbar-track {
    background-color: #22272A;
    z-index: 100000;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #F5FEFD;
    border-radius: 10px;
    z-index: 100000;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background-color: #17ACF7;
    z-index: 100000;
  }
  